import ArrayTableBody from "./ArrayTableBody"
import ArrayTableHeader from "./ArrayTableHeader"

const ArrayTable = ({className, fields, data, children}) => {



    return <table className={className}>
        <ArrayTableHeader fields={fields} />
        <ArrayTableBody fields={fields} data={data} > 
            {children}
        </ArrayTableBody>
    </table>
}
export default ArrayTable