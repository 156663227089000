import KeyValueTableRow from "./KeyValueTableRow";

const KeyValueTable = ({className, original, allowed, children}) => {
    if(!className) className = "table table-hover key-value-table"
    if(!allowed) allowed = []

    const object = Object.keys(original)
        .filter(key => Object.keys(allowed).includes(key))
        .reduce((obj, key) => {
            obj[allowed[key]] = original[key];
            return obj;
        }, {});

    var rows = []
    for (const [key, value] of Object.entries(object)) {
        rows.push(<KeyValueTableRow key={key} key_name={key} value={value} />)
    }
      
    return (
        <table className={className}>
            <tbody>
                {rows}
                {children}
            </tbody>
        </table>


    )
}

export default KeyValueTable