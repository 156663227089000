import { useEffect, useState } from "react";
import { euroFormatter } from "../../functions/formatters";

const ProductCell = ({ line, setPrice, cartLineTotal }) => {
    var delivery_date_trans = 'Leverdatum'
    const [cartLines, setCartLines] = useState([])
    var price = parseFloat(line.price_in_vat )
    var discount_price = parseFloat(line.disc_price_in_vat)

    useEffect(() => {
        var total_price = parseFloat(line.price_in_vat * line.quantity)
        var total_discount_price = parseFloat(line.disc_price_in_vat  * line.quantity)
        setPrice(total_price, total_discount_price)

        const child_lines = line.cart_lines.map((child_line) => {   
            var child_price = parseFloat(child_line.price_in_vat )
            var child_discount_price = parseFloat(child_line.disc_price_in_vat  )
            var total_child_price = parseFloat(child_line.price_in_vat  * child_line.quantity )
            var total_child_discount_price = parseFloat(child_line.disc_price_in_vat  * child_line.quantity)
            setPrice(total_child_price, total_child_discount_price)

            return (<p className="text-muted small">
                <b>{ child_line.product_listing.product_object.category.name}</b>: 
                {child_line.product_listing.product_object.name } {(child_line.quantity != 1) ? " - " +  child_line.quantity + " stuks" : '' } { (child_price != 0) ? "(+" + euroFormatter(child_discount_price) +")" : ''}
                {(child_line.product_listing.product_object.category.form_type === "Quantity") ?
                    <>
                        <a 
                        href={`/rest/cartline/${child_line.id}/cart_quantity_remove/`} 
                        className="ms-3 my-1 py-0 btn btn-outline-light text-dark" 
                        type="button" id="button-plus"> 
                            <i className="fa fa-minus"></i> 
                        </a>
                        <a 
                        href={`/rest/cartline/${child_line.id}/cart_quantity_add/`} 
                        className="py-0 btn btn-outline-light text-dark" 
                        type="button" 
                        id="button-minus"> 
                            <i className="fa fa-plus"></i> 
                        </a>
                    </>
                    :null
                }
            </p> 
            )
        })
        setCartLines(child_lines)
    }, [line, setPrice]) 

    return (
        <div className="d-flex align-items-start">
            <div className="d-none d-md-flex aside">
                <a href={ line.product_listing.product_object.url }>
                    {(line.product_listing.product_object.image_small) ?  
                        <img 
                            src={line.product_listing.product_object.image_small} 
                            className="img-sm"/>
                        :
                        <img src="" className="img-sm"/>
                    }
                </a> 
            </div>
            <div className="ms-4" >
                <a href={line.product_listing.product_object.url} className="product-title ">{line.product_listing.product_object.name}</a> ({euroFormatter(discount_price)})
                {cartLines}
                {(line.product_listing.product_object.delivery_date) ? <p class="ms-auto mt-auto text-danger small">{delivery_date_trans}: {line.product_listing.product_object.delivery_date} </p> : '' }
            </div>

        </div>

    )
}

export default ProductCell; 