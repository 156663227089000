import { useEffect, useState } from "react"
import useHttp from "../../hooks/use-http"

const ChooseProduct = ({chosenProduct, setProduct}) => {
    const [productList, setProductList] = useState([])


    const {isLoading : formLoading, error : formError, sendRequest : sendForm} = useHttp( (data) =>{
        setProductList(data['results'])
        setProduct(data['results'][0].id)
    })

    useEffect( () => {
        sendForm({url:'/rest/product-listing/list_store/', json:true})
    }, [])
    const productsHtml = productList.map( (product, index) => <option value={product.id} selected={product.id===chosenProduct} >{product.product_object.name} ({product.product_object.max_people} personen)</option>)
    return <select onChange={ (e) => {console.log(e.target);setProduct(e.target.value)}} class="form-select w-50 mb-2 me-auto" aria-label="Default select example">
            {productsHtml}
        </select>
}

export default ChooseProduct