import { useCallback, useState } from "react";
import { AppSettings } from "../_custom/settings";
import { getCookie, csrfSafeMethod } from "../functions/getCookie";


var csrftoken = getCookie('csrftoken');

export const addAuthorization = async (requestObject) => {
    var body = JSON.stringify({'username':AppSettings.user, 'password':AppSettings.password})
    const url_prefix = Object.keys(AppSettings).includes('portal_prefix') ? AppSettings.portal_prefix : ''
    var token_response = await fetch( 
        AppSettings.base_url + '/' + url_prefix + 'api-token-auth/' 
        , { 'method':'POST',
            'body':body, 
            'data':body, 
            'headers':{
                'Accept':'application/json',  
                'Content-Type':'application/json',  
                "X-Requested-With":'XMLHttpRequest',
                "X-CSRFToken":csrftoken
            }
        }
    )
    var token = await token_response.json()
    requestObject['headers']['Authorization'] = 'Token ' +  token.token
    return requestObject
}


const useForm = (applyData) => {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const sendRequest = useCallback(async (requestConfig, ) => {
        setIsLoading(true);
        setError(null);
        try{
            var requestObject = {
                 method: requestConfig.method ? requestConfig.method : 'GET'
                ,headers: requestConfig.headers ? requestConfig.headers : {}
                ,body: requestConfig.data ? requestConfig.data : null
            }
            if (!csrfSafeMethod(requestConfig.method)) { // && !this.crossDomain
                requestObject['headers']["X-CSRFToken"] = csrftoken;
                requestObject['headers']["X-Requested-With"] = 'XMLHttpRequest';
                requestObject['headers']['Content-Type'] = 'multipart/form-data; boundary=----WebKitFormBoundary';
            }   
            var url = requestConfig.url
            if(AppSettings.debug){
                if(url.replace(AppSettings.base_url, "").startsWith("/rest/")){
                    requestObject = await addAuthorization(requestObject)
                }
                if(!url.startsWith('http')){
                    url = AppSettings.base_url + url
                }
            }
            const response = await fetch(url, requestObject)
            const addAnother = requestConfig.data ? requestConfig.data.has('_addanother') : false
            var validated_form = {
                valid : false,
                status : response.status,
                addAnother : addAnother,
                redirect_url : null,
                navigate_url : null,
                form : null,
            }
            const catch_response = response.clone()
            try{
                const response_data = await response.json()
                console.log(response_data)
                validated_form = {...validated_form, ...response_data}
                validated_form['valid'] = response_data['success']
                console.log('test')
                
            }catch{
                validated_form['form'] = await catch_response.text()
                console.log('test2')
            }
            applyData(validated_form)
            
        } catch(err){
            console.log('error: ' + err)
            setError(err.message || 'Something went wrong!')
        }
        setIsLoading(false)
    }, [applyData])

    return {
         isLoading
        ,error
        ,sendRequest
    }

}

export default useForm