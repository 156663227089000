import { useState } from "react";

function humanize(str) {
    return str
        .replace(/^[\s_]+|[\s_]+$/g, '')
        .replace(/[_\s]+/g, ' ')
        .replace(/^[a-z]/, function(m) { return m.toUpperCase(); });
  }
  
  

const KeyValueTableRow = ({key_name, value}) => {
    const [expanded, setExpanded] = useState(false)
    var classes=''
    if(expanded) classes = 'expanded'
    return (
        <tr>
            <td >{humanize(key_name)}</td>
            <td> <p onClick={() => setExpanded(p => !p)} className={`table-text m-0 ${classes}`} >{value}</p> </td>
        </tr>
    )
}

export default KeyValueTableRow