export const boolShowHideParents = (e) =>{
    var bool_parent_selectors = Array.from(document.getElementsByClassName("bool-parent-selector"))
    bool_parent_selectors.forEach( (element) =>{
        const parent_element = document.getElementById('wrapper-'+element.dataset.bool_select_parent)
        if(parent_element){
            if (element.checked) {  
                parent_element.classList.remove("d-none");
            }
            else{
                parent_element.classList.add("d-none");
            }
        }
    })
}



export const charShowHideParents = (e) =>{
    var bool_parent_selectors = Array.from(document.getElementsByClassName("char-parent-selector"))
    bool_parent_selectors.forEach( (element) =>{
        const parent_element = document.getElementById('wrapper-'+element.dataset.char_select_parent)
        if(parent_element){
            if (element.checked) {  
                parent_element.classList.remove("d-none");
            }
            else{
                parent_element.classList.add("d-none");
            }
        }
    })
}
