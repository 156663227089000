import { useEffect, useState } from "react"
import CartLine from "./CartLine"
import { euroFormatter } from "../../functions/formatters"

const Cart = () => {
    let [pageContext, setPageContext] = useState({} )
    let [cartLines, setCartLines] = useState([])
    let [cartTotal, setCartTotal] = useState({discount_price:0, price:0})

    const confirm_trans = 'Naar Bevestigen'
    const continue_cart = 'Verder Winkelen'
    const discount_trans = 'Discount'
    const subtotal_trans = 'Subtotal'
    const note_trans = 'Including VAT, excluding shipping costs'

    useEffect(() => {  
        let text = document.getElementById('page-context').textContent  
        setPageContext( JSON.parse(text) )  
    }, [])  

    useEffect(() => {
        if(pageContext.cart_lines){
            console.log(pageContext.cart_lines)
            const html_cart_lines = pageContext.cart_lines.map((cart_line) => {
                return <CartLine  key={cart_line.id} setCartTotal={setCartTotal} line={cart_line} />
            })
            setCartLines(html_cart_lines)
        }
    },[pageContext])


    return (

        <div className="">
            <div className="row pb-2 border-bottom cart-header text-muted small text-uppercase">
                <div className="col-8">
                    Product
                </div>
                <div className="col-2 text-center">
                    Quantity
                </div>
                <div className="col-2 text-center">
                    Price
                </div>
            </div>
            <div className="row cart-body">
                {cartLines}
            </div>
            <div class="col-8 col-md-3 offset-2 offset-md-9">
				<div class="">
					<div class="card-body">
						{ (cartTotal.price - cartTotal.discount_price != 0) ?
                            <>
                                <dt>{discount_trans}:</dt>
                                <dd class="text-end">{ euroFormatter( cartTotal.price - cartTotal.discount_price)}</dd>
                            </>
                            : '' 
                        }
                        <dt>{subtotal_trans}:</dt>
						<dd class="text-end">{euroFormatter(cartTotal.discount_price)}</dd>
						<hr class="" />
						<small>
							<p class="small text-muted text-center mb-3">
							{note_trans}
							</p>
						</small>
					</div> 
				</div> 
			</div> 
        </div>


    )

}

export default Cart;