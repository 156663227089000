import useHttp from '../../hooks/use-http'
import { useCallback, useEffect, useState } from 'react';
import ProductCard from './ProductCard/ProductCard';
import LoadingCard from './ProductCard/LoadingCard';
import GroupCard from './ProductCard/GroupCard';
import i18next from '../../i18n'

const StoreApp = (props) => {
    const group = window.location.pathname.split('/')[2]
    const search = window.location.search.replace('?', '&')
    const [dataUrl, setDataUrl] = useState(null)
    const [categoryUrl, setCategoryUrl] = useState(null)
    var categories_trans = i18next.t('categories_trans')
    useEffect( () =>{
        setDataUrl('/rest/product-listing/list_store/?slug_name=' + group +search)
        setCategoryUrl('/rest/product-group/list_store/?slug_name=' + group +search)
    }, [group, search])

    const [groups, setGroups] = useState([])
    const [page, setPage] = useState(0)
    const [appendUrl, setAppendUrl] = useState('')
    const [products, setProducts] = useState([])
    const [productCount, setProductCount] = useState(0)

    const fetchData = useCallback( (data) => {
        var response = JSON.parse(data)
        setAppendUrl(response.next)
        setProductCount(response.count)
        setProducts( (prevData) =>{
            return prevData.concat(response.results)
        })
    }, [])

    const fetchCategories = (data) => {
        var response = JSON.parse(data)
        setGroups( (prevData) =>{
            return prevData.concat(response.results)
        })
    }

    const {isLoading : dataLoading, error : dataError, sendRequest : getData} = useHttp(fetchData)
    const {sendRequest : getCategories} = useHttp(fetchCategories)

    useEffect( () =>{
        if(dataUrl){
            setProducts([])
            getData({url:dataUrl})
        }
    }, [dataUrl])

    useEffect( () =>{
        if(categoryUrl){
            getCategories({url:categoryUrl})
        }
    }, [categoryUrl])

    var products_html = products.map( product => {
        return <ProductCard key={product.id} product={product} />
    })

    var groups_html = groups.map( group => {
        return <GroupCard key={group.id} group={group} />
    })

    if(products_html.length === 0) products_html = <h5 className='ms-5'>Helaas, we hebben geen producten gevonden</h5>

    const load_products = [...Array(12).keys()].map( (id) => { return <LoadingCard key={id} /> })

    return (
        <>
            <div className="container">
                <div className='row'>
                    <h5 className='col-4 p-5'>{productCount} Producten</h5>
                    <div class="col-8 border-start">
                        <h6 className='mb-1'>{categories_trans}</h6>
                        <a className='small' href={window.location.pathname.split('/').slice(0, 2).join('/')} >Zie alle producten</a>
                        <div class="category-list">
                            {groups_html}
                        </div>
                    </div>
                </div>
            </div>
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12 filter-pane">
                        <div class="filter-pane ">
                        </div>
                    </div>
                    <div class="col">
                        <div class="row store-row">
                            { (dataLoading & products.length === 0) ? 
                                <>
                                    {load_products}
                                </>
                                : (dataError) ?
                                    <h6>Er ging is iets mis bij het laden</h6>
                                    :
                                    products_html
                            }
                        </div>
                        {(appendUrl) ? 
                            <div className='btn btn-primary' onClick={() => getData({url:appendUrl}) } >Meer producten</div>
                            :
                            null
                        }
                    </div>
                </div>
            </div>
        </>
    );
}

export default StoreApp;
