import { useEffect, useState } from "react"
import ReservationPrice from "./ReservationPrice/ReservationPrice"
import useHttp from "../../hooks/use-http"
import KeyValueTable from "../../UI/KeyValueTable/KeyValueTable"
import { euroFormatter } from "../../UI/Formatters/formatters"


const ReservationOverview = (props) => {

    const [reservationData, setReservationData] = useState(null)
    const {isLoading : getFormLoading, error : getFormError, sendRequest : getData} = useHttp((data) => {setReservationData(data);})

    useEffect(() => {  
        getData({url:'/rest/reservation/current/retrieve_store/', json:true})
    }, [])  

    if(!reservationData){
        return <div></div>
    }

    const table_fields = {'full_name': 'Naam','company': 'Bedrijf','people': 'Aantal mensen','email': 'Email','phone': 'Telefoon'}
    const addition_fields = <>
        <tr>
            <td>Betaalmethode</td>
            <td>{reservationData.payment_method_object.name}</td>
        </tr>

    </> 

    return <div className="container">
        <div className="row my-4">
            <div className="col-12 col-md-4">
                <KeyValueTable original={reservationData} allowed={table_fields} >
                    {addition_fields}
                </KeyValueTable>
            </div>
            <div className="col-12 col-md-8">
                <ReservationPrice data={reservationData}/>
            </div>
            { (reservationData.deposit_amount !=  reservationData.total_amount_in_vat)?
                <div className="justify-content-center mt-3 ms-auto col-12 col-md-8 alert alert-warning" >
                    <i className="bi bi-info-circle me-2"/>
                    Je hoeft enkel nu een aanbetaling te doen om de boeking te bevestigen: {euroFormatter(reservationData.deposit_amount)}
                </div>
            :null
            }
            <div className="col-12 d-flex justify-content-end mt-5">
                <a href="/rest/reservation/current/pay_reservation/" className="border-2 btn btn-outline-primary px-5 py-3">Betalen</a>
            </div>
        </div>
    </div>
}

export default ReservationOverview