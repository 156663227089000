import ArrayTable from "../../../UI/ArrayTable/ArrayTable"
import {euroFormatter, imageFormatter} from "../../../UI/Formatters/formatters"
const ReservationPrice = (props) => {
    const table = {
        'product_object.image':{
            'label': '',
            'formatter': (value, row, id) => {
                if(value){
                    return <img className="small-image" src={value.image_small} />
                }
            },
        }, 
        'description':{
            'label': 'Omschrijving'
        }, 
        'people':{
            'label': 'Gasten'
        }, 
        'total_amount_in_vat': {
            'label': 'Bedrag',
            'formatter':euroFormatter,
        },       
    }

    const lines = <ArrayTable className="reservation-table table table-hover m-0" data={props.data.reservation_line_objects} fields={table} >
            <tr> <td colSpan={'100%'}> Subtotaal: {euroFormatter(props.data.total_amount_ex_vat)}</td> </tr>
            <tr> <td colSpan={'100%'}> BTW: {euroFormatter(props.data.total_amount_vat)}</td> </tr>
            <tr> <td colSpan={'100%'}> Totaal: {euroFormatter(props.data.total_amount_in_vat)}</td> </tr>
        </ArrayTable>
    return <>
        {lines}

    </>
}

export default ReservationPrice