import React, { useEffect, useState } from 'react';
import DateCard from './DateCard';
import useForm from '../../hooks/use-form'
import useHttp from '../../hooks/use-http'
import ChooseProduct from './ChooseProduct';
const ProductReservation = (props) => {
    const [selectedDate, setSelectedDate] = useState(new Date(new Date().getTime() + 24 * 60 * 60 * 1000));
    const [product, setProduct] = useState(props.product)
    const [formUrl, setFormUrl] = useState('/rest/product-listing/'+product+'/select_availability/?date=' + selectedDate.toISOString().split('T')[0])
    const [formHTML, setFormHTML] = useState(null)

    const formHandler = (form) =>{
        if(form.valid){
            if(form.redirect_url){
                setFormUrl(form.redirect_url)
            }
            else if(form.navigate_url){
                window.location.href = form.navigate_url
            }else{
                window.location.reload()
            }
        }
        else if(form.status == 422 || form.status === 200){
            setFormHTML(form['form'])
        }
        else if(form.status == 404){
            setFormHTML('<h6>Er ging helaas iets mis bij het ophalen van de informatie</h6>')
        }
        else{
            setFormHTML('<h6>Er ging helaas iets mis bij het laden</h6>')
        }
    }
    const {isLoading : formLoading, error : formError, sendRequest : sendForm} = useForm(formHandler)


    const handleDateChange = (date) => {
        setSelectedDate(new Date(date));
    };

    const renderNextDays = (n = 18) => {
        const nextDays = [];
        for (let i = 0; i < n; i++) {
          const nextDate = new Date(selectedDate);
          nextDate.setDate(selectedDate.getDate() + i);
          nextDays.push(nextDate);
        }
        return nextDays.map((date, index) => {
            return <DateCard key={date} active={date.toISOString() === new Date(selectedDate).toISOString()} date={date} handleDateChange={handleDateChange}/>
        })
    };
    
    useEffect( () => {
        sendForm({url:formUrl})
    }, [formUrl])
    
    useEffect( () => {
        setFormUrl('/rest/product-listing/'+product+'/select_availability/?date=' + selectedDate.toISOString().split('T')[0])
    }, [product, selectedDate])

    const submitForm = (event) => {
        event.preventDefault()
        var formData = new FormData(event.target)
        setFormHTML(null)
        sendForm({url:formUrl, method:'POST', data:formData})
    }

    return (
    <div className='container '>
        <h5>Reserveren</h5>
        {(!props.product) ? 
            <ChooseProduct chosenProduct={product} setProduct={setProduct} />
            :null
        }
        <div className='d-flex align-items-center'>
            <input className='form-control' style={{width:'fit-content'}} type="date" value={selectedDate.toISOString().split('T')[0]} onChange={(e) => handleDateChange(e.target.value)} />
            <div class="date-row" >
                {renderNextDays()}
            </div>
        </div>
        { (formLoading) ?
            <div class="py-5 d-flex justify-content-center "> 
                <div class="text-center spinner-border" role="status">
                    <span class="visually-hidden">Loading...</span>
                </div>
            </div>
            :
            <form onSubmit={submitForm} className="d-flex flex-column checkout-form" >
                <div 
                    className={`row date-availability`}
                    dangerouslySetInnerHTML={{
                        __html: formHTML
                    }}
                />
                <button className="mx-auto mt-2 border-2 btn btn-outline-primary px-5 py-3 " type="submit" value="submit">Kies</button>
            </form>
        }
    </div>
    );
};


export default ProductReservation