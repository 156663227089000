import ReviewStars from '../../../UI/ReviewStars/ReviewStars'
import i18next from '../../../i18n'

const GroupCard = (props)  => {
    return (
        <div class="category-card">
            <div class="category-card-inner d-flex flex-column">
                <a class="category-image" href={props.group.url} > 
                    <div class="image-wrapper w-100">
                        { (props.group.image) ?
                            <div>
                                <img alt={props.group.name} class="u-expanded-width lazyload  " data-image-width="1067" data-image-height="1600" data-src={props.group.image} />
                            </div>
                            : ''
                        }
                    </div>
                </a>
                <div class="category-description">
                    <a href={props.group.url} > <h6 class="category-title">{props.group.name}</h6></a>
                </div>
            </div>
        </div>
    )

}

export default GroupCard;