import { useEffect, useState } from "react"
import ProductForm from "./ProductForm/ProductForm"
import ProductImages from "./ProductImages/ProductImages"
import Modal from "../../UI/Modal/Modal"
import ModalContext from "../../store/ModalContext"
import ProductReservation from "../ProductReservaton/ProductReservation"

const ProductDetail = (props) => {
    const [pageContext, setPageContext] = useState('')

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalContent, setModalContent] = useState(null);

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const modalContext = {
        isModalOpen,
        modalContent,
        closeModal,
        openModal,
        setModalContent,
    } 
 

    useEffect(() => {  
        let text = document.getElementById('page-context').textContent  
        setPageContext(JSON.parse(text))  
    }, [])  

    if(!pageContext){
        return <div></div>
    }
    console.log(pageContext)
    return <ModalContext.Provider value={modalContext}>
            <Modal /> 
            <div className="detail-page-wrapper">
                <ProductImages product={pageContext} />
                <ProductForm  product={pageContext} setModalContent={setModalContent} openModal={openModal} closeModal={closeModal}/>
            </div>
            <ProductReservation product={pageContext.id} />
        </ModalContext.Provider>

}


export default ProductDetail