import { useEffect, useState } from "react"
import useHttp from "../../hooks/use-http"
import ReservationPrice from "./ReservationPrice/ReservationPrice"
import KeyValueTable from "../../UI/KeyValueTable/KeyValueTable"
import { euroFormatter } from "../../UI/Formatters/formatters"


const ReservationConfirmed = (props) => {
    const [reservationData, setReservationData] = useState(null)
    const {isLoading : getFormLoading, error : getFormError, sendRequest : getData} = useHttp((data) => {setReservationData(data);})

    useEffect(() => {  
        getData({url:'/rest/reservation/'+window.location.pathname.split('/')[3]+'/retrieve_store/', json:true})
    }, [])  

    if(!reservationData){
        return <div></div>
    }
    const table_fields = {'full_name': 'Naam','company': 'Bedrijf','people': 'Aantal mensen','email': 'Email','phone': 'Telefoon'}
    const addition_fields = <>
        <tr>
            <td>Status</td>
            <td>{reservationData.status_object.status}</td>
        </tr>
        <tr>
            <td>Betaalmethode</td>
            <td>{reservationData.payment_method_object.name}</td>
        </tr>
        <tr>
            <td>Betaald bedrag</td>
            <td>{euroFormatter(reservationData.paid_amount)}</td>
        </tr>
    </> 

    return <div className="container">
        <div className="row my-4">
            <div className="col-12 col-md-10 mx-auto">
                <h5>Reserveringdetails</h5>
                <KeyValueTable original={reservationData} allowed={table_fields} >
                    {addition_fields}
                </KeyValueTable>
                <ReservationPrice data={reservationData}/>
            </div>
        </div>
    </div>
}

export default ReservationConfirmed