import ImageGallery from "react-image-gallery";


const ProductImages = ({product}) => {
    const images = product.product_object.product_image_objects.map( (image, index) => {
        return {
            original: image.image,
            thumbnail : image.image

        }
    })

    return <ImageGallery items={images} />;


}

export default ProductImages