import React, { useContext, useState } from 'react';
import ModalContext from '../../store/ModalContext';

const Modal = (props) => {
    const context = useContext(ModalContext)
    var classes = (context.isModalOpen) ? 'custom-modal active d-flex flex-column' : 'custom-modal d-flex flex-column';

    return (
        <>
            {(context.isModalOpen) ? <div className="custom-modal-backdrop" onClick={context.closeModal} /> : '' }
            <div className={classes}>
                <div className="custom-modal-content" style={{ "zIndex":"1" }}>
                    <button className='custom-modal-close btn btn-white' onClick={context.closeModal}>
                        <i className="bi bi-x-octagon"></i>
                    </button>
                    { (context.modalContent) ? context.modalContent.props.children : null }
                </div>
            </div>
        </>
    );
};

export default Modal;