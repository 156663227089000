import { useEffect, useState } from "react"

const QuantityCell = ({line, changeCartline}) => {  
    const [quantity, setQuantity] = useState(line.quantity)

    useEffect( () => {
        const timer = setTimeout(() => {
            if(line.quantity != quantity){
                changeCartline(line.id, Math.max(quantity, 0))
            }   
        }, 500);
        return () => clearTimeout(timer);
    }, [quantity])

    const line_errors = line.errors.map((error) => {    
        return (<div class="alert alert-danger flex-column align-items-start" role="alert"> {error} </div>)
    })

    return (
        <div class="quantity-col col"> 
            <button type="button" className="btn delete-line" onClick={() => setQuantity(0)}>
                <i class="bi bi-trash"></i>
            </button>
            {line_errors}
            <div class="btn-group cart-line-quantity" role="group" aria-label="Small button group">
                <div 
                    className="py-1 btn btn-outline-light text-dark d-flex justify-content-center align-items-center" 
                    onClick={() => setQuantity( (q) => q-1)}
                    type="button" id="button-plus"> 
                    <i className="fa fa-minus"></i> 
                </div>
                <input 
                    data-id={line.id} 
                    type="text" 
                    min={1}
                    class="btn btn-quantity btn-outline-light form-control text-dark pe-0"  
                    onChange={e => setQuantity(parseInt(e.target.value))}
                    value={quantity} />
                <div
                    className="py-1 btn btn-outline-light text-dark d-flex justify-content-center align-items-center" 
                    type="button" 
                    onClick={() => setQuantity( (q) => q+1)}
                    id="button-minus"> 
                    <i className="fa fa-plus"></i> 
                </div>
            </div>
        </div>
    )
}

export default QuantityCell;