import ProductDescription from "./ProductDescription"
import KeyValueTable from "../../../UI/KeyValueTable/KeyValueTable"

const ProductForm = ({product, closeModal, openModal, setModalContent}) => {
    const table = {'max_people': 'Max. capaciteit',}
    const product_specs = <>
        <KeyValueTable className="text-start table table-hover key-value-table table-light" original={product.product_object} allowed={table} >
            <tr>
                <td>Regulier tarief</td>
                <td>€5,50 per persoon per uur</td>
            </tr>
            <tr>
                <td>Motor</td>
                <td>Volledig Elektrisch</td>
            </tr>
            <tr>
                <td>Schipper</td>
                <td>Inclusief</td>
            </tr>
        </KeyValueTable>
    </>

    console.log(product)
    

    return(
        <div class="product-form-cell ">
            <div className="product-information">
                <ProductDescription product={product.product_object}/>
                {product_specs}
            </div>
        </div>
    )
}

export default ProductForm;