
const LoadingSpinner = (props) => {
    var classes = (props.small) ? "spinner-border-sm " :""
    return (
        <div className={`d-flex w-100 ${props.html_class}`}>
            <div className={`spinner-border mx-auto ${classes}`} role="status" aria-hidden="true">
            </div>
        </div> 
    )
}
export default LoadingSpinner