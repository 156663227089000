import { useState } from "react";

const DateCard = ({active, date, handleDateChange}) => {
    
    const formatDate = (date) => {
        const options = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' };
        return date.toLocaleDateString(undefined, options);
    };
    
    // const getAvailability = () => {
    //     const availability = [
    //         { time: '10:00 AM', available: true },
    //         { time: '11:00 AM', available: false },
    //         { time: '12:00 PM', available: true },
    //         { time: '01:00 PM', available: false },
    //         { time: '02:00 PM', available: true },
    //       ];
    //     return setAvailability(availability)
    // }

    return (
        <div type="button"  onClick={() => handleDateChange(date.toISOString().split('T')[0])} > 
            <div className={`date-card ${(active) ? 'active': ''}`} >
                <p>{formatDate(date)}</p>
            </div>
        </div>
    );
}


export default DateCard