import dayjs from "dayjs";

export function operateFormatter(value, row, index) {
    return <a class="details options-menu" href="javascript:void(0)" title="See Details">,
        <i class="bi bi-three-dots-vertical"></i>,
      </a>  
    
  }

  
export function imageFormatter(value, row, id){
    if(value != null){
        if(value.image_small){
            return <img alt="table" className="table-image" src={value.image_small} />
        }   
        return ""
    }
}

export function euroFormatter(value, row, id){
    if(value != null){
        return  "€" + value.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          })
    }
}
export function percentageFormatter(value, row, id){
    if(value != null){
        return  value *100  + "%" 
    }
}

export function hrefFormatter(value, row, id){
    if(value != null){
        return  <a href={value} download> <i class="bi bi-download"></i></a>
    }
}

export function dateFormatter(value, row, id, format = 'DD-MM-YYYY'){
    if(value != null){
        var date = Date.parse(value)
        return dayjs(date).format(format)
    }
}
export function datetimeFormatter(value, row, id, format = 'DD-MM-YYYY HH:mm'){
    if(value != null){
        var date = Date.parse(value)
        return dayjs(date).format(format)
    }
}

export const genericRelationFormatter = (value, row, id, many=true, field='name') =>{
    if(value){
        return value[field]
    }
}

export function booleanFormatter(value, row, id){
    if(value){
        return <i class="bi bi-check-lg text-success"></i>
    } 
    else if (value === false){
        return <i class="bi bi-x-lg text-danger"></i>
    }
    return '-'
}

export function translatedPercFormatter(value, row, id){
    if( value !== "100%"){
      return <span class="badge rounded-pill bg-danger">{value}</span>
    }
    return <span class="badge rounded-pill border border-secondary text-secondary">{value}</span>
  }
  