import { useEffect, useState } from "react"
import useForm from "../../hooks/use-form"
import { boolShowHideParents, charShowHideParents } from "../../functions/showHideParents"
import Helmet from "react-helmet"
import LoadingSpinner from "../../UI/Loading/LoadingSpinner"
const Form = (props) => {
    const formElement = document.getElementById('britge-form')
    const [formUrl, setFormUrl] = useState(formElement.dataset.url)
    const buttonText = formElement.dataset.button || 'Verder'
    const recaptcha = formElement.dataset.recaptcha || false
    const [recaptchaScript, setRecaptchaScript] = useState(null)
    const [formHTML, setFormHTML] = useState(null)

    const formHandler = (form) =>{
        setFormHTML(null)
        if(form.valid){
            if(form.redirect_url){
                setFormUrl(form.redirect_url)
            }
            else if(form.navigate_url){
                window.location.href = form.navigate_url
            }else{
                window.location.reload()
            }
        }
        else if (form.status === 422 || form.status === 200){
            setFormHTML(form['form'])
        }
        else{
            setFormHTML('<h6>Helaas ging er iets mis aan onze kant. Neem aub contact met ons op</h6>')
        }
    }
    const {isLoading : formLoading, error : formError, sendRequest : sendForm} = useForm(formHandler)
    useEffect( () => {
        sendForm({url:formUrl})
    }, [formUrl])

    const submitForm = (event) => {
        event.preventDefault()
        var formData = new FormData(event.target)
        sendForm({url:formUrl, method:'POST', data:formData})
    }

    useEffect( () => {
        var parent_selectors = Array.from(document.getElementsByClassName("bool-parent-selector"))
        parent_selectors.forEach( (element) =>{
            element.addEventListener('change', boolShowHideParents)
        })
        boolShowHideParents()

        var parent_selectors = Array.from(document.getElementsByClassName("char-parent-selector"))
        parent_selectors.forEach( (element) =>{
            element.addEventListener('change', charShowHideParents)
        })
        boolShowHideParents()
    }, [formHTML])

    useEffect( () => {
        if(formHTML && recaptcha){
            const prev_rec = document.getElementById("recaptcha_script");
            if(prev_rec) prev_rec.remove()
            setRecaptchaScript(
                <Helmet>
                    <script id="recaptcha_script" src='https://www.google.com/recaptcha/api.js'></script> 
                </Helmet>
            )
        }
    }, [formHTML, recaptcha])


    return <div>
        {recaptchaScript}

        { (formError)? 
            <h6>Helaas ging er iets mis bij ons.</h6>
            : 
            (formLoading && formHTML !== null) ?
                <LoadingSpinner />  
                :
                <form onSubmit={submitForm} className="api-form" >
                    <div 
                        className={`row`}
                        dangerouslySetInnerHTML={{
                            __html: formHTML
                        }}
                        />
                    <button className="w-auto mx-auto mb-2  btn btn-primary d-block " type="submit" value="submit">{buttonText}</button>
                </form>
        }
    </div>
}

export default Form