const ArrayTableBody = ({fields, data, children}) => {

    const getValue = (field, row) => {
        field = field.split('.')
        var val = row
        field.forEach( (nested) => {
            val = val[nested]
        })
        return val

    }

    const rows = data.map( (row) => {
        const cells = Object.keys(fields).map( (field) => {
            if(fields[field].formatter){ //check if a formatter is send
                return <td>{fields[field].formatter(getValue(field, row))}</td>
            }
            return <td>{getValue(field, row)}</td>
        })
        return <tr>
            {cells}
        </tr> 
    })

    return <tbody>
        {rows}
        {children}
    </tbody>
}
export default ArrayTableBody